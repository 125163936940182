import React, { useEffect, useState } from "react"

export default function Likes({ slug }) {
  const [likes, setLikes] = useState(0)
  const [dislikes, setDislikes] = useState(0)

  useEffect(() => {
    async function fetchLikes(type) {
      const response = await fetch("https://api2.xavi.net/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query ($s: String!, $t: String!) {
                                likesCount(where: { slug: $s, type: $t })
                            }`,
          variables: {
            s: slug,
            t: type,
          },
        }),
      })
      if (response.status !== 200) {
        if (type === "like") setLikes(0)
        else setDislikes(0)
      } else {
        const data = await response.json()
        if (type === "like") setLikes(data.data.likesCount)
        else setDislikes(data.data.likesCount)
      }
    }
    fetchLikes("like")
    fetchLikes("dislike")
  }, [slug])

  return (
    <small style={{ color: "red" }}>
      {" "}
      <span role="img" aria-label="likes">
        👍
      </span>{" "}
      {likes}{" "}
      <span role="img" aria-label="dislikes">
        {" "}
        👎 {dislikes}{" "}
      </span>
    </small>
  )
}
